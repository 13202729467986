<template>
    <div>
        <PersonalData />
    </div>
</template>

<script>
import PersonalData from '@/components/settings/personalData/index.vue';

export default {
    name: 'SettingsGroupsOfPersons',
    components: {
        PersonalData,
    },
};
</script>
